import React, { useState } from "react";
import Handimg from "../assets/images/form.webp";
import axios from 'axios';

const Apply = () => {
  const [applydata, setapplydata] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Number: "",
    Qualification: "",
    Experience: "",
    Resume: "",
  });
  const [recipientEmail, setRecipientEmail] = useState('');

  const onchangedata = (e) => {
    const { name, value } = e.target;
    setapplydata((values) => ({ ...values, [name]: value }));
  };
  const onRecipientEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };


  const submitbtn = () => {
    const formData = {
      to: recipientEmail,
      subject: 'Apply Form',
      text: JSON.stringify(applydata)
    };

    axios.post('http://localhost:5000/send-email', formData)
      .then(response => {
        console.log(response.data.message);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };
  return (
    <div className="startdiv bg-[#f3f3f3]">
      <div className="mt-5">
        <div className="grid grid-cols-2 gap-4 mt-5 bg-white">
          <div class="col-span-1 md:p-0 p-5">
            <div className="md:p-7">
              <h1 className="text-[30px] font-bold">WE ARE HIRING!!!</h1>
              <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
                Yes, we are hiring. It's time to make your dream successful.
                Join us, build your experience. Innovation is in our hands, get
                ready to be a part of it.
              </p>
              <h2 className="md:text-[28px] text-[22px] font-bold mt-8">
                It's time to start living the life we've imagined
              </h2>
              <h1 className="md:text-[28px] text-[22px] font-bold float-right">
                - Henry James
              </h1>
            </div>
            <img src={Handimg} alt="" className="mt-5" />
          </div>
          <div class="col-span-1 bg-[#aaa8a891] pl-5 md:pl-14 pt-5 pb-7">
            <h1 className="text-[30px] font-semobold ">Come Work With Us</h1>
            <div className="grid grid-cols-2 gap-1 mt-2">
              <div class="col-span-1">
                <input
                  type="text"
                  className="border-2 p-2 md:w-10/12 w-11/12 bg-transparent border-gray-500 mt-3"
                  placeholder="First Name"
                  name="First_Name"
                  onChange={onchangedata}
                />
              </div>
              <div class="col-span-1">
                <input
                  type="text"
                  className="border-2 p-2 md:w-10/12 w-11/12 bg-transparent border-gray-500 mt-3"
                  placeholder="Last Name"
                  name="Last_Name"
                  onChange={onchangedata}
                />
              </div>
            </div>
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-5"
              placeholder="Email"
              name="Email"
              onChange={onchangedata}
            />
            <input
              type="text"
              className="border-2 p-2 w-11/12 bg-transparent border-gray-500 mt-5"
              placeholder="Phone Number"
              name="Number"
              onChange={onchangedata}
            />
            <div className="grid grid-cols-2 gap-1 mt-5">
              <div class="col-span-1">
                <input
                  type="text"
                  className="border-2 p-2 md:w-10/12 w-11/12 bg-transparent border-gray-500 mt-3"
                  placeholder="Qualification"
                  name="Qualification"
                  onChange={onchangedata}
                />
              </div>
              <div class="col-span-1">
                <input
                  type="text"
                  className="border-2 p-2 md:w-10/12 w-11/12 bg-transparent border-gray-500 mt-5"
                  placeholder="Years Of Experience"
                  name="Experience"
                  onChange={onchangedata}
                />
              </div>
            </div>

            <div class="photo-wraper">
              <div class="button w-11/12 md:w-5/12 mt-5 border-2 border-gray-500">
                <span>Upload Resume +</span>
                <input
                  id="register-photo"
                  onchange="readURL(this);"
                  type="file"
                  name="user[profile_attributes][photo]"
                />
              </div>
            </div>

            <button className="submitbtn mt-5" onClick={submitbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply;
