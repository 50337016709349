import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Logo from "../assets/images/logo.jpg";
import { AiOutlineHome } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import { AiOutlineCamera } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="py-4 px-5 md:px-16">
        <div className="flex justify-between items-start md:flex md:justify-between md:items-center">
          <div>
            <Link to="/">
              <img src={Logo} alt="" className="w-16 md:w-24" />
            </Link>
          </div>

          <div className="md:hidden">
            <button className="text-black" onClick={toggleSidebar}>
              {isOpen ? (
                <MdClose className="w-6 h-6" />
              ) : (
                <FiMenu className="w-6 h-6" />
              )}
            </button>
          </div>

          <ul
            className={`fixed top-0 right-0 h-full bg-gray-300 overflow-y-scroll transform transition-all duration-300 ease-in-out z-50 ${
              isOpen ? "translate-x-0 w-1/2" : "-translate-x-full w-0"
            }`}
          >
            <div className="flex justify-end p-4">
              <button className="text-black" onClick={toggleSidebar}>
                <MdClose className="w-6 h-6" />
              </button>
            </div>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <AiOutlineHome className="w-6 h-6" />
              </span>
              <Link to="/" className="text-black hover:bg-gray-400 px-2 py-1">
                Home
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <FaHandshake className="w-6 h-6" />
              </span>
              <Link
                to="/about"
                className="text-black hover:bg-gray-400 px-2 py-1"
              >
                About
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <AiOutlineCamera className="w-6 h-6" />
              </span>
              <Link
                to="/gallery"
                className="text-black hover:bg-gray-400 px-2 py-1"
              >
                Gallery
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <AiOutlineQuestionCircle className="w-6 h-6" />
              </span>
              <Link
                to="/whyus"
                className="text-black hover:bg-gray-400 px-2 py-1"
              >
                Why Us
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <HiOutlineUserGroup className="w-6 h-6" />
              </span>
              <Link
                to="/apply"
                className="text-black hover:bg-gray-400 px-2 py-1"
              >
                Apply for job
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col py-2 px-2">
              <span className="px-2">
                <BiPhoneCall className="w-6 h-6" />
              </span>
              <Link
                to="/enquiry"
                className="text-black hover:bg-gray-400 px-2 py-1"
              >
                Enquiry
              </Link>
            </li>
          </ul>

          <ul className="hidden md:block md:flex md:justify-center md:space-x-4 cursor-pointer">
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <AiOutlineHome className="w-6 h-6" />
              </span>
              <Link to="/" className="text-black hover:bg-gray-300 px-2 py-1">
                Home
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <FaHandshake className="w-6 h-6" />
              </span>
              <Link
                to="/about"
                className="text-black hover:bg-gray-300 px-2 py-1"
              >
                About
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <AiOutlineCamera className="w-6 h-6" />
              </span>
              <Link
                to="/gallery"
                className="text-black hover:bg-gray-300 px-2 py-1"
              >
                Gallery
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <AiOutlineQuestionCircle className="w-6 h-6" />
              </span>
              <Link
                to="/whyus"
                className="text-black hover:bg-gray-300 px-2 py-1"
              >
                Why Us
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <HiOutlineUserGroup className="w-6 h-6" />
              </span>
              <Link
                to="/apply"
                className="text-black hover:bg-gray-300 px-2 py-1"
              >
                Apply for job
              </Link>
            </li>
            <li className="flex items-center md:flex md:flex-col">
              <span className="px-2">
                <BiPhoneCall className="w-6 h-6" />
              </span>
              <Link
                to="/enquiry"
                className="text-black hover:bg-gray-300 px-2 py-1"
              >
                Enquiry
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
