import React, { useEffect, useState } from "react";
import First from "../assets/images/Intership/first.jpg";
import Second from "../assets/images/Intership/second.jpg";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Image1 from "../assets/images/Intership/IMG-20230802-WA0003.jpg";
import Image2 from "../assets/images/Intership/IMG-20230802-WA0004.jpg";
import Image3 from "../assets/images/Intership/IMG-20230802-WA0005.jpg";
import Image4 from "../assets/images/Intership/IMG-20230802-WA0006.jpg";
import Image5 from "../assets/images/Intership/IMG-20230802-WA0007.jpg";
import Image6 from "../assets/images/Intership/IMG-20230802-WA0008.jpg";
import Image7 from "../assets/images/Intership/IMG-20230802-WA0009.jpg";
import Image8 from "../assets/images/Intership/IMG-20230802-WA0010.jpg";
import Image9 from "../assets/images/Intership/IMG-20230802-WA0011.jpg";
import Image10 from "../assets/images/Intership/IMG-20230802-WA0012.jpg";
import Image11 from "../assets/images/Intership/IMG-20230802-WA0013.jpg";
import Image12 from "../assets/images/Intership/IMG-20230802-WA0014.jpg";
import Image13 from "../assets/images/Intership/IMG-20230802-WA0015.jpg";
import Image14 from "../assets/images/Intership/IMG-20230802-WA0016.jpg";
import Image15 from "../assets/images/Intership/IMG-20230802-WA0017.jpg";
import Image16 from "../assets/images/Intership/IMG-20230802-WA0018.jpg";
import Image17 from "../assets/images/Intership/IMG-20230802-WA0019.jpg";
import Image18 from "../assets/images/Intership/IMG-20230802-WA0020.jpg";
import Image19 from "../assets/images/Intership/IMG-20230802-WA0021.jpg";
import Image20 from "../assets/images/Intership/IMG-20230802-WA0022.jpg";
import Image21 from "../assets/images/Intership/IMG-20230802-WA0023.jpg";
import Image22 from "../assets/images/Intership/IMG-20230802-WA0024.jpg";

const Internship = (props) => {
  console.log(props.internship);
  console.log(props.systems);
  const [move, setmove] = useState(false)
  const [move1, setmove1] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);

  function closeModal() {
    setIsOpen(false);
  }
  function closeModal1() {
    setIsOpen1(false);
  }
  const additembtn = (index) => {
    setIsOpen(true);
    setSelectedImages(props.internship.slice(index)); 
  };
  const additembtn1 = (index) => {
    setIsOpen1(true);
    setSelectedImages1(props.systems.slice(index)); 
  };
  const secondImage=props.internship.slice(0,4)
  
  const thirdImage=props.systems.slice(0,2)
  useEffect(() => {
 
    if (props.systems.length>2) {
      setmove(true)
    }
  }, [props.systems])
  useEffect(() => {
    if (props.internship.length>4) {
      setmove1(true)
    }
 
  }, [props.internship])
  const openModal = () => {
    setSelectedImages(props.internship);
    setIsOpen(true);
  };

  const openModal1 = () => {
    setSelectedImages1(props.systems);
    setIsOpen1(true);
  };
  return (
    <>
      <h1 className="text-[20px] font-bold text-center">
        DSR SYSTEMS Honored to Host Dr. Mylswamy Annadurai, an Icon in Space
        Research
      </h1>

      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        <span className="text-[20px] font-bold text-black">DSR SYSTEMS</span>{" "}
        had the privilege of hosting Dr. Mylswamy Annadurai, a celebrated
        scientist and former Project Director of India's groundbreaking lunar
        probe, Chandrayaan-1. Dr. Annadurai's visit to DSR SYSTEMS was met with
        excitement and admiration as he shared his invaluable knowledge and
        experiences in space exploration.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        As a prominent figure in the Indian Space Research Organisation (ISRO),
        Dr. Annadurai's contributions to India's space missions have earned him
        the revered title of the "Moon Man of India." His remarkable
        achievements in aerospace engineering have left an indelible mark on the
        nation's space program and have been a source of inspiration for
        countless aspiring scientists and engineers.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        During his visit, Dr. Annadurai engaged in enlightening discussions with
        the team at DSR SYSTEMS, sharing his vision for the future of space
        exploration and technological advancements. His passion for pushing the
        boundaries of human knowledge and exploring the mysteries of the cosmos
        resonated deeply with the talented minds present.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        "Space is a vast frontier waiting to be explored," said Dr. Annadurai.
        "Innovation and collaboration are the keys to unlocking the secrets of
        the universe. Together, we can achieve the seemingly impossible."
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        The team at DSR SYSTEMS was captivated by Dr. Annadurai's profound
        wisdom and dedication to the pursuit of scientific excellence. His visit
        served as a reminder of the immense potential within each individual to
        contribute to the advancement of space research and technology.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        DSR SYSTEMS, expressed his gratitude for the opportunity to host Dr.
        Annadurai, stating, "Having Dr. Annadurai visit DSR SYSTEMS was an honor
        and a privilege. His expertise and experiences in space exploration have
        left a lasting impact on our team, inspiring us to continue striving for
        greatness in our work."
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal">
        Dr. Annadurai's visit to DSR SYSTEMS was a momentous occasion, inspiring
        the team to aim higher and embrace the challenges that come with
        exploring the cosmos. As they forge ahead on their path of innovation,
        they are fueled by Dr. Annadurai's words of wisdom and motivated to make
        a lasting impact in the field of space research.
      </p>
      <div className="flex gap-4 justify-center items-center">
      {thirdImage.map((image, index) => (
          <img
            key={index}
            src={image.url}
            alt={`Internship image ${index + 1}`}
            className="h-[35rem] w-[30rem] object-cover"
            onClick={() => additembtn(index + 2)}  // Passing the next index (after the first two images)
          />
        ))}
{move1 && 
        <span className="add" onClick={openModal}><h1>+</h1></span>
}      </div>

      <h1 className="text-[30px] font-bold text-center mt-5">INTERSHIP</h1>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        <span className="text-[20px] font-bold text-black">DSR SYSTEMS</span>, a
        leading technology company specializing in cutting-edge solutions for
        PCB Assembly and advanced electronics, recently concluded a highly
        successful and transformative internship program. The program provided
        young engineering enthusiasts with an immersive experience in the field
        of PCB Assembly and equipped them with essential skills to excel in the
        electronics industry.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        Under the guidance of DSR SYSTEMS' experienced engineers, the interns
        delved into various aspects of PCB design, fabrication, and assembly
        processes. They gained insights into soldering techniques, component
        placement, and quality control measures, mastering the intricate art of
        creating reliable and efficient circuit boards.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        DSR SYSTEMS, expressed his enthusiasm for the program's success,
        stating, "Our PCB Assembly internship program aims to nurture and mentor
        the future leaders of the electronics industry. We take pride in
        empowering young talent with the technical expertise required to drive
        innovation and excellence in PCB technology."
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        The PCB Assembly internship program, spanning [duration], welcomed a
        group of passionate interns from diverse engineering disciplines.
        Throughout the program, these aspiring engineers were exposed to
        hands-on training, industry-best practices, and state-of-the-art
        technology in PCB Assembly.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        The interns shared their appreciation for the immersive learning
        experience and the chance to work with cutting-edge equipment and
        industry-leading professionals. They acknowledged that the program not
        only enriched their technical knowledge but also provided them with
        valuable insights into the demands and challenges of the electronics
        industry.
      </p>
      <p className="text-[18px] leading-8 text-justify text-[#5C5C5C] font-normal mt-2">
        The success of the PCB Assembly internship program at DSR SYSTEMS
        reflects the company's commitment to nurturing talent and fostering
        innovation in the electronics domain. As DSR SYSTEMS continues to
        revolutionize the electronics industry, the organization remains
        dedicated to providing aspiring engineers with opportunities to thrive
        and contribute to the ever-evolving world of PCB technology.
      </p>
      <div className="mydata grid grid-cols-4 gap-4 mt-5">
      {secondImage.map((data, index) => (
          <img
            key={index}
            src={data.url}
            alt={`systems image ${index + 1}`}
            className="h-[25rem] w-[30rem] object-cover"
            onClick={() => additembtn1(index + 4)}  // Display all images in the modal when clicked
          />
        ))}
{move && 
       <span className="add1" onClick={openModal1}><h1>+</h1></span>
}      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="intershipmodel"
      >
        <div className="btn">
          <button onClick={closeModal}>
            <AiOutlineCloseCircle className="h-6 w-6" />
          </button>
        </div>

        <div className="check1">
          <Swiper
            pagination={{
              // Optional pagination for the swiper
              type: "progressbar",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {props.systems.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="check">
                  <img
                    src={image.url}
                    className="h-100% object-cover"
                    alt={`Modal image ${index + 1}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        className="intershipmodel"
      >
        <div className="btn">
          <button onClick={closeModal1}>
            <AiOutlineCloseCircle className="h-6 w-6" />
          </button>
        </div>

        <div className="check1">
          <Swiper
            pagination={{
              // Optional pagination for the swiper
              type: "progressbar",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {props.internship.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center items-center py-2">
                  <img
                    src={image.url}
                    className="h-[30rem] object-cover"
                    alt={`Modal image ${index + 1}`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Modal>
    </>
  );
};

export default Internship;
