import React from "react";
import whyimg from "../assets/images/whyus.webp";

const Whyus = () => {
  return (
    <div className="startdiv bg-[#f3f3f3]">
      <div className="whyusdiv flex">
        <div>
          <h2 className="text-[34px] font-semibold mt-5 ">Why Us ?</h2>
          <ul className="ml-14 mt-5">
            <li>
              20 years of experience in Electronics Manufacturing Industries
            </li>
            <li>Eight years of Service to the Electronics Industry</li>
            <li>Well educated and trained staff</li>
            <li>Around Fifty customers in and around Coimbatore</li>
            <li>
              Around Six Research and Development Customers in and around
              Coimbatore
            </li>
            <li>
              Visual inspection stands as proof for the quality of the product
            </li>
            <li>Provided a Testing Certificate to ensure product quality.</li>
            <li>Commitment to continual improvement</li>
            <li>Six Colleges have signed MOU with us</li>
            <li>Frequent Industrial Visit by the reputed colleges</li>
            <li>
              Testing of Assembled Boards as per the procedures with testing
              certificates
            </li>
            <li>We are following ISO 9001:2015 quality management systems.</li>
          </ul>
        </div>
        <img alt="" src={whyimg} className="w-1/4 h-1/4 pl-5 mt-7 whyimg" />
      </div>
    </div>
  );
};

export default Whyus;
